import React from "react"
import styled from "styled-components"
import { graphql, Link} from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import Section, { ColumnsSection, SectionColumn } from '../components/Section';
import MainImageSection from '../components/MainImageSection';
import { MainTitle, SectionTitle }  from '../components/Titles';

import { colors, dimensions } from '../shared/styles';
import makeBlogArticlePath from "../shared/makeBlogArticlePath"

/* ---------------------------------------------- */
/* ---------------------------------------------- */


const ArticleList = styled(ColumnsSection)`
  padding-top : 5rem;
  padding-bottom : 5rem;
  flex-wrap : wrap;

  @media screen and (max-width: 600px) {
    justify-content : center;
    align-items : center;
  }

`;

const ArticleCard = styled.div`
  width : 30rem;
  height : 45rem;
  border : 1px solid black;
  background-color : white;
  margin : 3rem;
  margin-left : 1rem;
  margin-right : 1rem;
  padding : 2rem;

  & h3 {
    font-size : 2.4rem;
    line-height : 3rem;
  }

  @media screen and (max-width: 425px) {
    width : auto;
    height : auto;
    padding : 5%;
  }
`;

const ArticleImageContainer = styled.div`
  position : relative;
  padding-top : 66%;
  width : 100%;
`;

const ArticleImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size : cover;
  background-position : center center;
`;


const AuthorDateContainer = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : space-between;
  margin-top : 2rem;
`;

const ArticleDate = styled.div`
  font-size : 1.6rem;

  @media screen and (max-width: 425px) {
    font-size : 2rem;
  }
`;

const ArticleAuthor = styled(ArticleDate)`
`;

const CardLink = styled(Link)`
  text-decoration : none;
  color : black;

  &:active, &:visited {
    color : inherit;
  }
`


const BlogPage = ({data}) => {

  console.log(data);
  return (
    <Layout>
      <SEO title="Blog" />
      <MainImageSection image={require('../images/blog_image.jpg')}>
        <MainTitle
          strings={[
            "Retrouvez ici nos derniers articles", "autour de l’innovation et de l’expérience client"
          ]}
          />
      </MainImageSection>
      <ArticleList>
        {
          data.allMarkdownRemark.edges.map(
            ({ node }) => {
              const formattedDate = new Intl.DateTimeFormat(undefined, {month : 'long', day:'numeric', year : 'numeric'}).format(new Date(node.frontmatter.date));
              const path = makeBlogArticlePath(node.frontmatter.title);

              return (
                <ArticleCard key={path}>
                  <CardLink to={path} >
                    <ArticleImageContainer>
                      <ArticleImage style={{backgroundImage : `url(${node.frontmatter.thumbnail})`}} />
                    </ArticleImageContainer>
                    <AuthorDateContainer>
                      <ArticleAuthor>{node.frontmatter.author}</ArticleAuthor>
                      <ArticleDate>{formattedDate}</ArticleDate>
                    </AuthorDateContainer>
                    <h3>{node.frontmatter.title}</h3>
                  </CardLink>
                </ArticleCard>
              );
            }
          )
        }
      </ArticleList>
    </Layout>
  )
   
};

export const query = graphql`
{
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    limit: 1000
  ) {
    edges {
      node {
        frontmatter {
          date
          title
          thumbnail
          author
        }
      }
    }
  }
}
`;

export default BlogPage
